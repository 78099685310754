<template>
  <div class="ExamOnline_Edit">
    <Preview title="考试预览" backUrl="/teacher/exam" :data="data" />
  </div>
</template>

<script>
import Preview from "./Preview.vue";
import { ExamPreview } from "@/libs/api/online";
export default {
  name: "examOnline_edit",
  components: { Preview },
  data() {
    return {
      id: null,
      data: [],
    };
  },
  activated() {
    this.id = this.$route.query.id;
    this.query();
  },
  methods: {
    query() {
      if (!this.id) return;
      this.data = [];
      // this.$axios_supermall
      //   .get("/hnjxjy-core/TeaCourseStudentExam/examPreview?id=" + this.id)
      ExamPreview({ id: this.id }).then((res) => {
        res.data.bigQuestions.forEach((item) => {
          this.data.push({
            totalScore: res.data.totalScore,
            desc: res.data.desc,
            type: item.questionName,
            child: [item.questionList],
          });
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
.ExamOnline_Edit {
}
</style>