<style lang="less" scoped>
.Preview-Exam-Module {
  .stu-module-header {
    padding: 20px;
    background: #fff;
    box-shadow: @shadow;
    margin-bottom: 15px;
  }
  .content {
    .red {
      color: #ff0000;
    }
    .green {
      color: #009999;
    }
    .slot_total {
      display: inline-block;
      margin-right: 12px;
      font-weight: bold;
    }
    .items {
      border: 1px solid @borderBottomColor;
      margin-bottom: 20px;
      .header {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        border-bottom: 1px solid @borderBottomColor;
        .h_left {
          span {
            font-weight: bold;
            font-size: 16px;
          }
          span:first-child {
            display: inline-block;
            margin-right: 10px;
          }
          span:last-child {
            display: inline-flex;
            .h_left_title {
              font-weight: bold;
            }
          }
        }
        .h_right {
          font-weight: bold;
          font-size: 16px;
        }
      }
      @media screen and (max-width: 1200px) {
        .header {
          flex-flow: column;
        }
      }
      .list_ul {
        padding: 20px;
        padding-bottom: 0;
        li {
          padding: 10px 0 10px 12px;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -2px;
            height: 4px;
            width: 4px;
            border-radius: 50%;
            background: #000;
          }
          span {
            font-size: 16px;
          }
        }
      }
      .referAnswer {
        background-color: #f9f9f9;
        padding: 20px;
        border: 1px dashed #d8d8d8;
        overflow: hidden;
        color: #999;
        margin: 0 20px;
        margin-top: 20px;
        font-size: 16px;
      }
      .parse {
        padding: 20px 40px;
        p {
          font-size: 16px;
        }
        .parse_label {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
<style>
  .items img{
    width: 150px;
  }
</style>
<template>
  <div class="Preview-Exam-Module">
    <div class="stu-module-header">
      <div class="stu-module-title">{{ title }}</div>
      <el-button @click="back" type="primary">返回</el-button>
    </div>
    <el-card>
      <div slot="header">
        试卷要求：<span>{{ data[0].desc }}</span>
      </div>
      <div class="content">
        <el-collapse v-model="activeNames">
          <el-collapse-item v-for="(item, index) in data" :key="index" :name="index">
            <template slot="title">
              <span class="slot_total red">{{ item.total }}</span>
              <span class="slot_total">{{ item.type }}</span>
            </template>

            <div class="items" v-for="(c, i) in item.child" :key="i">
              <div v-for="(d, l) in c" :key="l">
                <div class="header">
                  <span class="h_left">
                    <span class="red">得分（{{ d.itemScore }}）</span>
                    <span>
                      <span>{{ i=i+1 }}、</span>
                      <p v-html="d.title" class="h_left_title"></p>
                    </span>
                  </span>
<!--                  <p class="h_right red">得分：（{{ d.itemScore }}）</p>-->
                </div>
                <ul class="list_ul" v-if="d.options && d.options.length">
                  <li v-for="(l, j) in d.options" :key="j">
                    <span>{{ l.itemKey }}）</span>
                    <span>{{ l.itemVal }}</span>
                  </li>
                </ul>
                <div class="referAnswer">
                  <p class="green">参考答案：</p>
                  <p v-html="d.questionKey"></p>
                </div>
                <div class="parse">
                  <p class="parse_label green">解析：</p>
                  <p v-html="d.questionAnalyze"></p>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "preview-exam-module",
  props: {
    title: { type: String, default: "" },
    backUrl: String,
    data: { type: Array, default: [] },
  },
  data() {
    return {
      active: null
    };
  },
  computed: {
    activeNames: {
      get() {
        // 展开全部
        return this.active || this.data.map((m,i) => i);
      },
      set(v) {
        this.active = v;
      }
    }
  },
  methods: {
    back() {
      this.$router.push(this.backUrl);
    },
  },
};
</script>